import React, { Fragment, useEffect, useState } from "react"
import ColorDots from "../components/ColorDots/ColorDots"
import Layout from "../components/layout"
import EmailSignup from "../components/EmailSignup/EmailSignup"
import Footer from "../components/Footer/Footer"

import styles from "./faqStyles.module.scss"

const Question = ({ q, a, open, style }) => {
  const [renderAnswer, setRenderAnswer] = useState(open)
  const [showAnswer, setShowAnswer] = useState(open)

  useEffect(() => {
    if (!showAnswer) {
      const t = setTimeout(() => {
        setRenderAnswer(false)
      }, 201)
      return () => {
        clearTimeout(t)
      }
    }
  }, [showAnswer])
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...style,
      }}
    >
      <div style={{ display: "flex", width: "900px" }}>
        <div>
          <div
            className={styles.expandButton}
            onClick={() => {
              if (!renderAnswer && !showAnswer) {
                setRenderAnswer(true)
                setShowAnswer(true)
              } else if (renderAnswer && showAnswer) {
                setShowAnswer(false)
              }
            }}
          >
            {showAnswer ? "-" : "+"}
          </div>
        </div>
        <div
          style={{
            textAlign: "left",
            paddingLeft: "30px",
          }}
        >
          <h2>{q}</h2>

          <p
            className={[
              showAnswer
                ? styles.appear
                : renderAnswer
                ? styles.disappear
                : "hidden",
            ].join(" ")}
            style={{ marginTop: "15px" }}
          >
            {a}
          </p>
        </div>
      </div>
    </div>
  )
}

const FAQ = () => {
  return (
    <Layout>
      <div className="centered">
        <h1 style={{ margin: "100px auto 40px auto" }}>
          Frequently Asked Questions
        </h1>
        <ColorDots style={{ margin: "40px auto" }}></ColorDots>
        {[
          {
            q: "What is medical marijuana?",
            a:
              "Medical marijuana refers to using the cannabis flower or extracts for various ailments and conditions. Ingested or inhaled, the body absorbs the plants active ingredients, cannabinoids. Cannabis contains over 80 cannabinoids, many of which have been found to have therapeutic benefits - the 2 major ones being cannabidiol and (CBD) and tetrahydrocannabinol (THC - thank goodness for acronyms!). The potential health benefits of these and other cannabinoids comprise the reason for KindBio's entry into this space.",
          },
          {
            q: "How do I find the right strain for my needs?",
            a:
              "A knowledgeable budtender could point you in the right direction, but we have a better idea. KindBio is developing a science driven approach to developing personalized medicine made specifically for a patient’s particular needs and conditions. Our soon-to-launch health portal will allow you to match your desired results and KindBio's unique high quality strains. Sign-up for our email and be the first to know about KindBio's true strain certified therapy.",
          },
          {
            q: "What are the various ways to consume medical marijuana?",
            a: (
              <Fragment>
                Traditionally, most people think of cannabis as only for
                smoking, but there are many ways to consume this helpful plant
                medicine. Main alternatives include inhaling vaporizing
                concentrates and ingestion of edibles.
                <br />
                <br />
                Concentrates have been purified in order to separate the
                medicine from the plant matter, leaving you with a much cleaner
                and clearer experience. Kind Bio concentrates are made using
                CO2- the same extraction process used to extract caffeine from
                coffee beans. Because this is a natural component of the air we
                breathe, there is no added taste or risk to health when
                inhaling.
                <br />
                <br />
                Ingestion can come in the form of your favorite snacks, such as
                mints or jerky. Digesting takes a bit more time to start but has
                a much more even and long-lasting effect. At Kind Bio, our
                edibles come in very specific and small quantities so you can
                find the perfect dose that gives you relief throughout your day.
                KindBio also make 2 edibles that literally melt on your tongue
                for quick absorption.
              </Fragment>
            ),
          },
          {
            q: "What are the effects of medical marijuana?",
            a: (
              <Fragment>
                Like your fingerprint, we each have a unique biology and
                interaction with the medicine we take. If you are new to
                cannabis, take small doses in a safe setting to find what works
                best for you. However, there are two main effects people
                receive:
                <br />
                <br />
                First is relief. Many people use medical marijuana to relax the
                body. Certain products and strain varieties relieve chronic pain
                and aid with sleep.
                <br />
                <br />
                Second is stimulation. Other types of cannabis give a boost in
                energy and creativity. These products are used to feel more
                inspired and engaged with life.
                <br />
                <br />
                Confused about which strain is best for you? Join the health
                portal and find out what strain will give you the results you
                are looking for.
                <br />
                <br />A dosing journal, like the one KindBio has developed, is
                an excellent way for you to keep tabs on your cannabis journey.
                Recording your unique marijuana experience on the KindBio Health
                Portal helps you track the medicinal effects of your cannabis
                therapy, while also providing KindBio with critical data needed
                to develop the best plant-based medicine for your specific
                needs.
              </Fragment>
            ),
          },
          {
            q: "Where can I find KindBio products?",
            a: <Fragment>Coming soon!</Fragment>,
          },
        ].map((faq, i) => {
          return (
            <Question
              key={i}
              q={faq.q}
              a={faq.a}
              open={i === 0 ? true : false}
              style={{ marginTop: "30px" }}
            ></Question>
          )
        })}
      </div>
      <EmailSignup></EmailSignup>
      <Footer></Footer>
    </Layout>
  )
}

export default FAQ
